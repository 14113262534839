import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Search, Hamburger } from '@vhl/icons'
import { AnimatePresence, motion } from 'framer-motion'
import CloseIcon from '../../CloseIcon'
import loadable from '@loadable/component'
import { useLocation, useToggle } from 'react-use'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const MobileSidebar = loadable(() => import('../MobileSidebar'))

const MobileNavbar = () => {
  const { isDesktop } = useBreakpoints()
  const [isSidebarOpen, toggleSidebar] = useToggle(false)

  const location = useLocation()
  const sidebarRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    toggleSidebar(false)
  }, [location.pathname])
  useEffect(() => {
    if (isDesktop) return
    if (!sidebarRef.current) return
    if (isSidebarOpen) {
      disableBodyScroll(sidebarRef.current)
      return
    }
    enableBodyScroll(sidebarRef.current)
  }, [isSidebarOpen])

  return (
    <>
      <div className="sticky top-0 z-50 desktop:hidden flex justify-between px-4 py-[14px] border-[1px] bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg w-full">
        <Link to="/" className="max-w-[150px] w-1/2">
          <StaticImage
            src="../../../../images/logo/navbar.png"
            alt="Vietnam Health Literacy Image"
            className="cursor-pointer"
            quality={100}
            width={668}
            height={232}
            formats={['auto', 'avif', 'webp']}
          />
        </Link>
        <div className="flex items-center justify-end w-1/2 space-x-[32px]">
          <AnimatePresence exitBeforeEnter>
            {!isSidebarOpen && (
              <motion.span
                key="search-icon"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Search
                  width={24}
                  height={24}
                  strokeWidth={1}
                  onClick={() => {
                    toggleSidebar(true)
                    setTimeout(() => {
                      const searchBox = sidebarRef.current?.querySelector(
                        `input[name='search-box']`
                      ) as HTMLInputElement
                      searchBox?.focus()
                    }, 500)
                  }}
                />
              </motion.span>
            )}
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            {!isSidebarOpen ? (
              <motion.span
                key="hamburger-icon"
                initial={{ x: -25, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -5, opacity: 0 }}
              >
                <Hamburger
                  width={32}
                  height={32}
                  onClick={() => {
                    toggleSidebar(true)
                  }}
                />
              </motion.span>
            ) : (
              <motion.span
                initial={{ x: 15, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <CloseIcon
                  className="w-8 h-8 stroke-black"
                  onCloseFinished={() => toggleSidebar(false)}
                />
              </motion.span>
            )}
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence>
        {isSidebarOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.5 }}
            ref={sidebarRef}
            className="fixed top-[82px] left-0 bottom-0 z-10 w-full overflow-y-scroll bg-white"
          >
            <MobileSidebar toggle={toggleSidebar} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default MobileNavbar
